import { Box } from "@chakra-ui/react";
import HeroSectionAlt from "../components/HomePage/HeroSectionAlt";
// import VideoSearch from "../components/HomePage/VideoSearch";
import TenXCreator from "../components/HomePage/TenXCreator";
import Features from "../components/HomePage/Features";
import Creators from "../components/HomePage/Creators";
// import Pricing from "../components/HomePage/Pricing";
import Faq from "../components/HomePage/Faq";
import Grad from "../assets/grad.png";
import NewGrad from "../assets/newgrad.png";

const Home = () => {
  return (
    <Box w={"100%"} height={"100%"} position={["relative"]} bg={"rivr.black"}>
      <Box
        backgroundImage={Grad}
        backgroundPosition={{
          base: "top center",
          md: "top center",
          lg: "top center",
        }}
        backgroundRepeat={"no-repeat"}
        backgroundSize={"cover"}
      >
        <HeroSectionAlt />
      </Box>
      {/* <VideoSearch /> */}
      <TenXCreator />
      <Box
        w={"100%"}
        backgroundImage={NewGrad}
        backgroundPosition={"center center"}
        backgroundRepeat={"no-repeat"}
        backgroundSize={{ base: "300%", md: "200%", lg: "cover" }}
      >
        <Features />
      </Box>
      <Creators />
      {/* <Pricing /> */}
      <Faq />
    </Box>
  );
};

export default Home;
