import {
  Box,
  Flex,
  Heading,
  HStack,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";

const ThingToDo = ({ buttonText }) => {
  return (
    <Box
      bg={"gray.800"}
      py={1.5}
      px={4}
      borderRadius={"full"}
      borderColor={"whiteAlpha.200"}
      borderWidth={1}
      shadow={"0px 4px 0px #e43a19"}
      transition={"all 0.2s"}
      cursor="default"
      _hover={{
        backgroundColor: "whiteAlpha.300",
        transition: "all 0.2s",
        shadow: "0px 6px 0px #e43a19",
      }}
    >
      <Text>{buttonText}</Text>
    </Box>
  );
};

const TenXCreator = () => {
  return (
    <Box bg={"rivr.black"}>
      <Box position="relative" w="100%" overflow="hidden">
        <Flex
          w="100%"
          maxW={"1200px"}
          px={"6"}
          h="100%"
          justifyContent="center"
          flexDirection="column"
          mx="auto"
          zIndex={1}
          alignItems={"center"}
        >
          <HStack overflow="visible" height="5rem" justifyContent={"center"}>
            <Heading
              as="h2"
              size="lg"
              color="gray.50"
              textAlign="center"
              pt={1}
            >
              Become a
            </Heading>
            <Heading
              as="h2"
              size="3xl"
              color="rivr.red"
              textShadow={"0px 0px 24px #e43a1980"}
              textAlign="center"
              mt={4}
            >
              10x
            </Heading>
            <Heading
              as="h2"
              size="lg"
              color="gray.50"
              textAlign="center"
              pt={1}
            >
              creator
            </Heading>
          </HStack>
          <Heading
            as="h2"
            size={{ xl: "md", lg: "md", md: "md", sm: "sm" }}
            color="gray.300"
            fontWeight={"medium"}
            mt={2}
            textAlign="center"
            lineHeight="1.5"
            zIndex={1}
            maxW="780px"
            sx={{ textWrap: "pretty" }}
          >
            Search any video for highlights, spoken keywords, community
            insights, and more.
            <br />
            Rivr's Signals and Recommendations reduce scrubbing by 90%.
            <br />
            Take back your time. Do more of what you love.
          </Heading>
          <Wrap spacing={4} p={8} align={"center"} justify={"center"}>
            <WrapItem>
              <ThingToDo buttonText="Make more content" />
            </WrapItem>
            <WrapItem>
              <ThingToDo buttonText="Make better content" />
            </WrapItem>
            <WrapItem>
              <ThingToDo buttonText="Get to know your community" />
            </WrapItem>
            <WrapItem>
              <ThingToDo buttonText="Spend time with friends" />
            </WrapItem>
            <WrapItem>
              <ThingToDo buttonText="Hit the gym" />
            </WrapItem>
            <WrapItem>
              <ThingToDo buttonText="Learn something new" />
            </WrapItem>
            <WrapItem>
              <ThingToDo buttonText="Watch a sunset" />
            </WrapItem>
            <WrapItem>
              <ThingToDo buttonText="Start a garden" />
            </WrapItem>
            <WrapItem>
              <ThingToDo buttonText="Read a book" />
            </WrapItem>
            <WrapItem>
              <ThingToDo buttonText="Go for a walk" />
            </WrapItem>
          </Wrap>
        </Flex>
      </Box>
    </Box>
  );
};

export default TenXCreator;
