import {
  Box,
  Flex,
  Tooltip,
  Image,
  HStack,
  VStack,
  IconButton,
  Link,
  Button,
  useMediaQuery,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Divider,
} from "@chakra-ui/react";

import LogoFull from "../../assets/rivr-logo.svg";
import DiscordIcon from "../../assets/discord-icon";
import BlueSkyIcon from "../../assets/bs-icon";
import XIcon from "../../assets/x-icon";
import { ChevronRightIcon, HamburgerIcon } from "@chakra-ui/icons";

const Navigation = () => {
  const [isMobile] = useMediaQuery("(max-width: 1000px)");

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      {isMobile ? (
        <Box id={"navigation-mobile"} className={"navigation-wrapper-mobile"}>
          <Flex
            flexDirection={"row"}
            justifyContent={"space-between"}
            w={"100%"}
            px={"3"}
            py={"4"}
            position={"fixed"}
            top={"0"}
            zIndex={1000}
            backdropFilter={"blur(10px)"}
            bg={"#0C0D14CC"}
            transition={"all .5s"}
          >
            <Flex className="has-drawer" w="33%" justifyContent={"flex-start"}>
              <IconButton
                variant="outline"
                size="sm"
                icon={<HamburgerIcon />}
                color={"gray.50"}
                onClick={onOpen}
                aria-label="Navigation Menu"
              />
              <Drawer
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                size="xs"
              >
                <DrawerOverlay />
                <DrawerContent bg={"rivr.black"}>
                  <DrawerCloseButton />
                  <DrawerHeader />
                  <DrawerBody>
                    <Flex
                      flexDirection="column"
                      justifyContent={"space-between"}
                      h="100%"
                    >
                      <VStack spacing={3} mt={4}>
                        <Link w="100%" href="https://app.rivr.stream">
                          <Button
                            rightIcon={<ChevronRightIcon />}
                            w="100%"
                            aria-label="Early access"
                            colorScheme={"red"}
                            bg={"rivr.red"}
                            color={"gray.50"}
                          >
                            Early access
                          </Button>
                        </Link>
                        <Link w="100%" href="https://app.rivr.stream">
                          <Button
                            w="100%"
                            aria-label="Sign in"
                            variant={"outline"}
                          >
                            Sign in
                          </Button>
                        </Link>
                        <Divider color={"gray.700"} />
                        {/* <Link
                          w={"100%"}
                          href="https://rivr.stream/case-studies"
                        >
                          <Button w="100%" aria-label="Case studies">
                            Case studies
                          </Button>
                        </Link> */}
                        <Link w={"100%"} href="/">
                          <Button w="100%" aria-label="Creators">
                            Creators
                          </Button>
                        </Link>
                        <Link w={"100%"} href="/brands">
                          <Button w="100%" aria-label="Brands">
                            Brands
                          </Button>
                        </Link>
                        <Link w={"100%"} href="/roadmap">
                          <Button w="100%" aria-label="Roadmap">
                            Roadmap
                          </Button>
                        </Link>
                        <Link w={"100%"} href="/blog">
                          <Button w="100%" aria-label="Blog">
                            Blog
                          </Button>
                        </Link>
                        <Link w={"100%"} href="/careers">
                          <Button w="100%" aria-label="Careers">
                            Careers
                          </Button>
                        </Link>
                        <Divider color={"gray.700"} />
                        <Link
                          w={"100%"}
                          href="https://x.com/rivrstream"
                          isExternal
                        >
                          <IconButton
                            variant="solid"
                            aria-label="Twitter/X"
                            w={"100%"}
                            icon={<XIcon />}
                          />
                        </Link>
                        <Link
                          w={"100%"}
                          href="https://bsky.app/profile/rivr.stream"
                          isExternal
                        >
                          <IconButton
                            aria-label="Bluesky"
                            variant="solid"
                            w={"100%"}
                            icon={<BlueSkyIcon />}
                          />
                        </Link>
                        <Link
                          w={"100%"}
                          href="https://discord.gg/WTkWJKzyQK"
                          isExternal
                        >
                          <IconButton
                            aria-label="Discord"
                            variant="solid"
                            w={"100%"}
                            icon={<DiscordIcon />}
                          />
                        </Link>
                        Contact
                      </VStack>
                      <VStack spacing={3} mt={"auto"} mb={3}>
                        <Link
                          w={"100%"}
                          href="/privacy-terms-of-service"
                          onClick={onClose}
                        >
                          <Button w="100%" aria-label="Privacy Policy">
                            Privacy Policy
                          </Button>
                        </Link>
                        <Link
                          w={"100%"}
                          href="/privacy-terms-of-service#terms"
                          onClick={onClose}
                        >
                          <Button w="100%" aria-label="Terms of Service">
                            Terms of Service
                          </Button>
                        </Link>
                      </VStack>
                    </Flex>
                  </DrawerBody>
                </DrawerContent>
              </Drawer>
            </Flex>
            <Flex className="has-logo" w="33%" justifyContent={"center"}>
              <Link href="/">
                <Image src={LogoFull} w={"80px"} alt="Rivr Logo" />
              </Link>
            </Flex>
            <Flex
              className="has-icons-button"
              w="33%"
              justifyContent={"flex-end"}
            >
              <HStack spacing={"0"}>
                <Link href="https://x.com/rivrstream" isExternal>
                  <IconButton
                    variant="ghost"
                    size="sm"
                    aria-label="X/Twitter"
                    icon={<XIcon />}
                  />
                </Link>
                <Link href="https://bsky.app/profile/rivr.stream" isExternal>
                  <IconButton
                    aria-label="Bluesky"
                    variant="ghost"
                    size={"sm"}
                    icon={<BlueSkyIcon />}
                  />
                </Link>
                <Link href="https://discord.gg/WTkWJKzyQK" isExternal>
                  <IconButton
                    variant="ghost"
                    size="sm"
                    icon={<DiscordIcon />}
                    aria-label="Discord"
                  />
                </Link>
              </HStack>
            </Flex>
          </Flex>
        </Box>
      ) : (
        // Desktop Navigation
        <Box id={"navigation"} className={"navigation-wrapper"}>
          <Flex
            flexDirection={"row"}
            justifyContent={"space-between"}
            backdropFilter={"blur(10px)"}
            bg={"#0C0D14CC"}
            w="100%"
            px={"6"}
            py={"4"}
            position={"fixed"}
            top={"0"}
            zIndex={1000}
            className="navigation"
            transition={"all .5s"}
          >
            <Flex w="100%" maxW="1400px" mx={"auto"}>
              <Flex w={"33%"} justifyContent={"flex-start"}>
                <Link href="/">
                  <Image src={LogoFull} w={"100px"} alt="Rivr Logo" />
                </Link>
              </Flex>
              <HStack spacing={"8"} w={"33%"} justifyContent={"center"}>
                {/* <Link
                  color="gray.50"
                  fontWeight="semibold"
                  textAlign="center"
                  _active={{ color: "rivr.red" }}
                  _hover={{ color: "rivr.red" }}
                  href="https://rivr.stream/case-studies"
                  aria-label="Case studies"
                >
                  Case studies
                </Link> */}
                <Link
                  color="gray.50"
                  fontWeight="semibold"
                  textAlign="center"
                  _active={{ color: "rivr.red" }}
                  _hover={{ color: "rivr.red" }}
                  href="/"
                  aria-label="Creators"
                >
                  Creators
                </Link>
                <Link
                  color="gray.50"
                  fontWeight="semibold"
                  textAlign="center"
                  _active={{ color: "rivr.red" }}
                  _hover={{ color: "rivr.red" }}
                  href="/brands"
                  aria-label="Brands"
                >
                  Brands
                </Link>
                <Link
                  color="gray.50"
                  fontWeight="semibold"
                  textAlign="center"
                  _active={{ color: "rivr.red" }}
                  _hover={{ color: "rivr.red" }}
                  href="/roadmap"
                  aria-label="Roadmap"
                >
                  Roadmap
                </Link>
                <Link
                  color="gray.50"
                  fontWeight="semibold"
                  textAlign="center"
                  _active={{ color: "rivr.red" }}
                  _hover={{ color: "rivr.red" }}
                  href="/blog"
                  aria-label="Blog"
                >
                  Blog
                </Link>
                <Link
                  color="gray.50"
                  fontWeight="semibold"
                  textAlign="center"
                  _active={{ color: "rivr.red" }}
                  _hover={{ color: "rivr.red" }}
                  href="/careers"
                  aria-label="Careers"
                >
                  Careers
                </Link>
              </HStack>
              <HStack spacing={"4"} w={"33%"} justifyContent={"flex-end"}>
                <HStack spacing={"0"}>
                  <Tooltip position={"bottom"} label="Follow Twitter/X">
                    <IconButton
                      aria-label="X/Twitter"
                      variant="ghost"
                      icon={<XIcon />}
                      as="a"
                      href="https://x.com/rivrstream"
                      target="_blank"
                    />
                  </Tooltip>
                  <Tooltip position={"bottom"} label="Follow Bluesky">
                    <IconButton
                      aria-label="Bluesky"
                      variant="ghost"
                      as="a"
                      href="https://bsky.app/profile/rivr.stream"
                      target="_blank"
                      icon={<BlueSkyIcon />}
                    />
                  </Tooltip>
                  <Tooltip position="bottom" label="Join Discord">
                    <IconButton
                      variant="ghost"
                      aria-label="Discord"
                      icon={<DiscordIcon />}
                      as="a"
                      href="https://discord.gg/WTkWJKzyQK"
                      target="_blank"
                    />
                  </Tooltip>
                </HStack>
                <HStack spacing={"2"}>
                  <Link href="https://app.rivr.stream">
                    <Button w="100%" aria-label="Sign in" variant={"outline"}>
                      Sign in
                    </Button>
                  </Link>
                  <Link href="https://app.rivr.stream">
                    <Button
                      rightIcon={<ChevronRightIcon />}
                      w="100%"
                      aria-label="Early access"
                      colorScheme={"red"}
                      bg={"rivr.red"}
                      color={"gray.50"}
                    >
                      Early access
                    </Button>
                  </Link>
                </HStack>
              </HStack>
            </Flex>
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export default Navigation;
