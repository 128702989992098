import {
  Box,
  Flex,
  Heading,
  VStack,
  HStack,
  SimpleGrid,
  Image,
} from "@chakra-ui/react";

import BlazinglyFast from "../../assets/features/blazingly-fast.png";
import ContentAutomation from "../../assets/features/content-automation.png";
import FindAnything from "../../assets/features/find-anything.png";
import MoreThanAi from "../../assets/features/more-than-ai.png";
import NoInstalls from "../../assets/features/no-installs.png";
import Timeline from "../../assets/features/timeline.png";

const Features = () => {
  return (
    <Box position="relative" w="100%" overflow="hidden">
      <Flex
        w="100%"
        maxW={"1200px"}
        px={"6"}
        pt={[32, 32, 64]}
        h="100%"
        justifyContent="center"
        flexDirection="column"
        mx="auto"
        zIndex={1}
      >
        <VStack justifyContent={"center"} spacing={4}>
          <div
            id="features"
            style={{
              position: "relative",
              top: "-104px",
              visibility: "hidden",
            }}
          ></div>
          <Heading
            as="h2"
            size="lg"
            color="gray.50"
            textAlign="center"
            sx={{ textWrap: "balance" }}
          >
            Unlike any other creator tool
          </Heading>
          <Heading
            as="h2"
            size={{ xl: "md", lg: "md", md: "md", sm: "sm" }}
            color="gray.300"
            fontWeight={"medium"}
            textAlign="center"
            lineHeight="1.5"
            zIndex={1}
            maxW="600px"
            pb={6}
            sx={{ textWrap: "balance" }}
          >
            Designed by creators, for creators, Rivr combines next-gen
            technology with familiar content workflows.
          </Heading>
          <Flex
            h="100%"
            w="100%"
            borderRadius={"lg"}
            borderWidth={1}
            transition={"all 0.2s"}
            bg={"blackAlpha.700"}
            _hover={{
              borderColor: "rivr.red",
              bg: "blackAlpha.800",
              shadow: "0 15px 50px -12px rgba(228, 58, 25, 0.25);",
            }}
            backdropFilter={"blur(2px)"}
            p={[2, null, 12]}
            py={12}
            justifyContent={"center"}
          >
            <VStack justifyContent={"space-between"}>
              <Image
                src={Timeline}
                w="100%"
                mx={"auto"}
                mb={"-24px"}
                alt="Rivr Signal Timeline"
              />
              <VStack spacing={4} pt={[6, 3, 0]}>
                <Heading
                  as="h2"
                  size={["md", null, "lg"]}
                  color="gray.50"
                  textAlign="center"
                  px={8}
                >
                  Explore the Timeline
                </Heading>
                <Heading
                  as="h2"
                  px={12}
                  size={["sm", null, "md"]}
                  color="gray.300"
                  fontWeight={"medium"}
                  textAlign="center"
                  lineHeight="1.5 !important"
                  zIndex={1}
                  sx={{ textWrap: "balance" }}
                >
                  An innovative new way to understand video.
                </Heading>
              </VStack>
            </VStack>
          </Flex>
          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            spacing={4}
            className="grid-1"
            w="100%"
            h="100%"
          >
            <Flex
              h="100%"
              w="100%"
              borderRadius={"lg"}
              borderWidth={1}
              transition={"all 0.2s"}
              bg={"blackAlpha.700"}
              _hover={{
                borderColor: "rivr.red",
                bg: "blackAlpha.800",
                shadow: "0 15px 50px -12px rgba(228, 58, 25, 0.25);",
              }}
              backdropFilter={"blur(2px)"}
              p={(8, null, 12)}
              justifyContent={"center"}
            >
              <VStack justifyContent={"space-between"}>
                <Image
                  src={MoreThanAi}
                  w="100%"
                  maxW={"240px"}
                  mx={"auto"}
                  mb={"-32px"}
                  alt="Abstract representation of Go Beyond AI"
                />
                <VStack spacing={4}>
                  <Heading
                    as="h2"
                    size={["md", null, "lg"]}
                    color="gray.50"
                    textAlign="center"
                  >
                    Go beyond AI
                  </Heading>
                  <Heading
                    as="h2"
                    size={["sm", null, "md"]}
                    color="gray.300"
                    fontWeight={"medium"}
                    textAlign="center"
                    lineHeight="1.5 !important"
                    zIndex={1}
                    sx={{ textWrap: "balance" }}
                  >
                    Invent your own search terms – you’re not limited by what we
                    know.
                  </Heading>
                </VStack>
              </VStack>
            </Flex>
            <HStack spacing={4}>
              <Flex
                h="100%"
                w="50%"
                borderRadius={"lg"}
                borderWidth={1}
                transition={"all 0.2s"}
                bg={"blackAlpha.700"}
                _hover={{
                  borderColor: "rivr.red",
                  bg: "blackAlpha.800",
                  shadow: "0 15px 50px -12px rgba(228, 58, 25, 0.25);",
                }}
                backdropFilter={"blur(2px)"}
                p={[3, null, 6]}
                justifyContent={"center"}
              >
                <VStack justifyContent={"center"}>
                  <Image
                    src={NoInstalls}
                    maxW={"200px"}
                    w="100%"
                    mx={"auto"}
                    mb={"-24px"}
                    alt="Abstract representation of No Installs"
                  />
                  <VStack spacing={4} pb={6}>
                    <Heading
                      as="h2"
                      size={["md", null, "lg"]}
                      color="gray.50"
                      textAlign="center"
                    >
                      No installs
                    </Heading>
                    <Heading
                      as="h2"
                      size={["sm", null, "md"]}
                      color="gray.300"
                      fontWeight={"medium"}
                      textAlign="center"
                      lineHeight="1.5 !important"
                      zIndex={1}
                      sx={{ textWrap: "balance" }}
                    >
                      Runs from anywhere, anytime, 100% in the cloud.
                    </Heading>
                  </VStack>
                </VStack>
              </Flex>
              <Flex
                h="100%"
                w="50%"
                borderRadius={"lg"}
                borderWidth={1}
                transition={"all 0.2s"}
                bg={"blackAlpha.700"}
                _hover={{
                  borderColor: "rivr.red",
                  bg: "blackAlpha.800",
                  shadow: "0 15px 50px -12px rgba(228, 58, 25, 0.25);",
                }}
                backdropFilter={"blur(2px)"}
                p={[3, null, 6]}
                justifyContent={"center"}
              >
                <VStack justifyContent={"center"}>
                  <Image
                    src={BlazinglyFast}
                    maxH={"200px"}
                    w="100%"
                    mx={"auto"}
                    mb={["-18px", null, "-24px"]}
                    alt="Abstract representation of Go Fast"
                  />
                  <VStack spacing={4} pb={6}>
                    <Heading
                      as="h2"
                      size={["md", null, "lg"]}
                      color="gray.50"
                      textAlign="center"
                    >
                      Move fast
                    </Heading>
                    <Heading
                      as="h2"
                      size={["sm", null, "md"]}
                      color="gray.300"
                      fontWeight={"medium"}
                      textAlign="center"
                      lineHeight="1.5 !important"
                      zIndex={1}
                      sx={{ textWrap: "balance" }}
                    >
                      Analyze an hour of video in as little as one minute.
                    </Heading>
                  </VStack>
                </VStack>
              </Flex>
            </HStack>
          </SimpleGrid>
          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            spacing={4}
            className="grid-2"
            w="100%"
            h="100%"
          >
            <Flex
              h="100%"
              w="100%"
              borderRadius={"lg"}
              borderWidth={1}
              transition={"all 0.2s"}
              bg={"blackAlpha.700"}
              _hover={{
                borderColor: "rivr.red",
                bg: "blackAlpha.800",
                shadow: "0 15px 50px -12px rgba(228, 58, 25, 0.25);",
              }}
              backdropFilter={"blur(2px)"}
              p={12}
              order={{ base: 1, md: 2 }}
              justifyContent={"center"}
            >
              <VStack justifyContent={"space-between"}>
                <Image
                  src={FindAnything}
                  w="100%"
                  maxW={"240px"}
                  mx={"auto"}
                  mb={"-18px"}
                  alt="Abstract representation of Find Anything in Everything"
                />
                <VStack spacing={4}>
                  <Heading
                    as="h2"
                    size={["md", null, "lg"]}
                    color="gray.50"
                    textAlign="center"
                  >
                    Find anything in everything
                  </Heading>
                  <Heading
                    as="h2"
                    size={["sm", null, "md"]}
                    color="gray.300"
                    fontWeight={"medium"}
                    textAlign="center"
                    lineHeight="1.5 !important"
                    zIndex={1}
                    sx={{ textWrap: "balance" }}
                  >
                    Use Rivr Signals to find experiences that are seen, heard,
                    said, or felt.
                  </Heading>
                </VStack>
              </VStack>
            </Flex>
            <Flex
              h="100%"
              w="100%"
              borderRadius={"lg"}
              borderWidth={1}
              transition={"all 0.2s"}
              bg={"blackAlpha.700"}
              _hover={{
                borderColor: "rivr.red",
                bg: "blackAlpha.800",
                shadow: "0 15px 50px -12px rgba(228, 58, 25, 0.25);",
              }}
              backdropFilter={"blur(2px)"}
              p={12}
              order={{ base: 1, md: 2 }}
              justifyContent={"center"}
            >
              <VStack justifyContent={"space-between"}>
                <Image
                  src={ContentAutomation}
                  w="100%"
                  maxW={"240px"}
                  mx={"auto"}
                  mb={"-24px"}
                  alt="Abstract representation of Content Automation"
                />
                <VStack spacing={4}>
                  <Heading
                    as="h2"
                    size={["md", null, "lg"]}
                    color="gray.50"
                    textAlign="center"
                  >
                    Content reformatting
                  </Heading>
                  <Heading
                    as="h2"
                    size={["sm", null, "md"]}
                    color="gray.300"
                    fontWeight={"medium"}
                    textAlign="center"
                    lineHeight="1.5 !important"
                    zIndex={1}
                    sx={{ textWrap: "balance" }}
                  >
                    Reformat to vertical and post directly to social platforms.
                  </Heading>
                </VStack>
              </VStack>
            </Flex>
          </SimpleGrid>
        </VStack>
      </Flex>
    </Box>
  );
};

export default Features;
