import { Box, Image, Text, useBreakpointValue, VStack } from "@chakra-ui/react";
import Marquee from "react-fast-marquee";
import {
  AFK,
  Amazon,
  Bungie,
  DE,
  Epic,
  LC,
  StrikingDistance,
  THQ,
  Upforce,
  Raredrop,
  NorthBeach,
  NetEase,
  NCSoft,
  Logitech,
  LotoQuebec,
  WarnerBros,
  Gaggl,
  Respawn,
  YrsTruly,
} from "../../assets/logos";

const logos = [
  { src: AFK, name: "AFK" },
  { src: StrikingDistance, name: "Striking Distance Studios" },
  { src: THQ, name: "THQ" },
  { src: NetEase, name: "NetEase Games" },
  { src: NCSoft, name: "NCSoft" },
  { src: Logitech, name: "Logitech" },
  { src: Amazon, name: "Amazon Games" },
  { src: Bungie, name: "Bungie" },
  { src: DE, name: "Digital Extremes" },
  { src: Epic, name: "Epic Games" },
  { src: Gaggl, name: "Gaggl" },
  { src: Respawn, name: "Respawn" },
  { src: YrsTruly, name: "YRS Truly" },
  { src: LC, name: "Leviathan Core" },
  { src: LotoQuebec, name: "Loto Quebec" },
  { src: WarnerBros, name: "Warner Bros Games" },
  { src: Upforce, name: "Upforce Marketing" },
  { src: Raredrop, name: "Raredrop" },
  { src: NorthBeach, name: "North Beach Games" },
];

const B2BClients = () => {
  const gradientWidth = useBreakpointValue({ base: 100, md: 400 });
  const firstRow = logos.slice(0, 9);
  const secondRow = logos.slice(9);

  return (
    <VStack
      position={"relative"}
      width={"100%"}
      overflow={"hidden"}
      pt={[32, 32, 64]}
    >
      <Text
        textAlign={"center"}
        textTransform={"uppercase"}
        fontWeight={"bold"}
        letterSpacing={3}
        color={"gray.300"}
        fontSize={["sm", "sm", "md"]}
        pb={8}
      >
        Trusted by industry leaders
      </Text>
      <VStack w={"100%"} gap={[2, 2, 6]}>
        <Marquee
          gradient={true}
          gradientColor={"#0C0D14"}
          gradientWidth={gradientWidth}
          speed={40}
          autoFill={true}
        >
          {firstRow.map(({ src, name }, index) => (
            <Box key={index} mx={[6, 6, 8]}>
              <Image src={src} alt={`${name} Logo`} maxH={[8, 8, 12]} />
            </Box>
          ))}
        </Marquee>

        <Marquee
          gradient={true}
          gradientColor={"#0C0D14"}
          gradientWidth={gradientWidth}
          speed={40}
          autoFill={true}
          direction={"right"}
        >
          {secondRow.map(({ src, name }, index) => (
            <Box key={index} mx={[6, 6, 8]}>
              <Image src={src} alt={`${name} Logo`} maxH={[8, 8, 12]} />
            </Box>
          ))}
        </Marquee>
      </VStack>
    </VStack>
  );
};

export default B2BClients;
